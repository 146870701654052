@import "../Common/variables.module.scss";

.stepperPaper {
    width: 60%;
    min-width: 200px;
    padding: $padding;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: $flex-gap;
    @media (max-width: $COMPACT_SIZE_THRESHOLD) {
        width: 100%;
    }
}

.textFields {
    min-width: 180px;
    width: 80%;
    max-width: 400px;
}

.fitContentWidth {
    width: fit-content;
}

.chipContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: $flex-gap;
}

.accordionChipContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: $flex-gap;
    padding: $padding;
}

.createDayContainer {
    display: flex;
    gap: $flex-gap;
    flex-direction: row;
    flex-wrap: wrap;
}

.dateTimeCombineContainer {
    padding: $padding;
    gap: $flex-gap;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.dateTimeCombineContainerOutter {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.settingsContainer {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    padding: $padding;
    gap: $flex-gap;
    max-width: 700px;
}

.settingsMiddleContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: $flex-gap;
}

.settingsInnerContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
}

.containerDivider {
    width: 100%;
}

.ownerInfosContainer {
    display: flex;
    padding: $padding;
    gap: $flex-gap;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: flex-start;
    width: 40%;
    min-width: 300px;
}

.iconContainer {
    display: flex;
    align-items: center;
}

.informationButtonContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.informationButtonContainerDouble {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.accordion {
    width: 100%;
}

.accordionHeader {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.accordionDetailsHeader {
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    gap: $flex-gap;
}

.combinedDayTimeContainer {
    width: 40%;
    min-width: 320px;
    max-width: 800px;
    padding: $padding;
}

.accordionHeaderDiv {
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    flex-wrap: wrap;
    gap: $flex-gap;
}

.accordionHeaderDivInner {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: $flex-gap;
}

.daysContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    column-gap: 10px;

    .day {
        height: $day-height;
        @media (max-width: $COMPACT_SIZE_THRESHOLD) {
            height: $day-height-mobile;
        }
        width: 75px;
        background-color: $day-background;
        border-bottom: 1px dotted $day-border;
        border-right: 1px solid $day-border;
        border-left: 1px solid $day-border;

        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        cursor: pointer;

        /*Text unselectable*/
        -webkit-user-select: none; /* Safari */
        -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* IE10+/Edge */
        user-select: none; /* Standard */
        @media (hover: hover) {
            &:hover {
                background-color: $day-hover;
            }
        }

        &.first {
            border-top: 1px solid $day-border;
            height: $day-height + 1;
            @media (max-width: $COMPACT_SIZE_THRESHOLD) {
                height: $day-height-mobile + 1;
            }
        }

        &.hour {
            border-bottom: 1px solid $day-border;
        }

        &.last {
            border-bottom: 1px solid $day-border;
        }

        &.selection {
            background-color: $day-background-selected;
            border-left: 2px solid $day-border-selected;
            border-right: 2px solid $day-border-selected;
            @media (hover: hover) {
                &:hover {
                    background-color: hsl(hue($day-background-selected), 100%, 32%);
                }
            }
        }

        &.selectionStart {
            background-color: $day-background-selected;
            border-left: 2px solid $day-border-selected;
            border-right: 2px solid $day-border-selected;
            border-top: 2px solid $day-border-selected;
            @media (hover: hover) {
                &:hover {
                    background-color: hsl(hue($day-background-selected), 100%, 32%);
                }
            }
        }

        &.selectionEnd {
            background-color: $day-background-selected;
            border-left: 2px solid $day-border-selected;
            border-right: 2px solid $day-border-selected;
            border-bottom: 2px solid $day-border-selected;
            @media (hover: hover) {
                &:hover {
                    background-color: hsl(hue($day-background-selected), 100%, 32%);
                }
            }
        }
    }
}

.legendContainer {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    .legendItem {
        border-top: 1px solid $day-border;
        height: ($day-height * 4);
        @media (max-width: $COMPACT_SIZE_THRESHOLD) {
            height: ($day-height-mobile * 4);
        }
        padding-right: $padding;
    }
}

.outterTableContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    column-gap: $flex-gap;
}

.innerTableContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.timeFlex {
    display: flex;
    column-gap: 100px;
    row-gap: $flex-gap;
    flex-direction: row;
    flex-wrap: wrap;
}

.leftTimesContainer {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: $flex-gap;
    align-items: flex-start;
}

.timeContainer {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    flex-wrap: wrap;
}

.addedSlotListItem {
    &:hover {
        background-color: $list-item-hover;
    }
}
