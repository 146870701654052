$padding: 15px;
$COMPACT_SIZE_THRESHOLD: 900px;
$flex-gap: 15px;
$success-color: #8bc34a;
$error-color: #d11800;
$day-background: #eaeaea;
$day-hover: #cacaca;
$day-border: #999999;
$day-border-selected: hsl(64, 49%, 35%);
$day-background-selected: hsl(64, 49%, 54%);
$day-height: 15px;
$day-height-mobile: 20px;
$list-item-hover: #fafafa;
